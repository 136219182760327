import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoginRequest } from '../models/loginRequest';
import { RegisterRequest } from '../models/registerRequest';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  baseUrlUser = 'https://livelife-user-api.azurewebsites.net/api/';
  baseUrl = 'https://livelife-webapi.azurewebsites.net/api/v1/users/';

  constructor(private http: HttpClient) { }

  // logs the user
  loginUser(loginRequest: LoginRequest) {
    return this.http.post(`${this.baseUrlUser}login`, loginRequest);
  };

  // logs the user
  registerUser(registerRequest: RegisterRequest) {
    return this.http.post(`${this.baseUrlUser}register`, registerRequest);
  };

  // gets the user from the livelife middleware api
  getUser(currentUser) {
    return this.http.post(`${this.baseUrl}getuserbyappid`, currentUser);
  };

  // gets the netready user from the livelife middleware api (retrieved from the netready server via the neatready api)
  getNetReadyUser(userName) {
    return this.http.get(`${this.baseUrl}getnetreadyuser/${userName}`);
  };

  // gets the user profile from the middlware database
  getUserProfile(appId) {
    return this.http.get(`${this.baseUrl}getuserprofile/${appId}`);
  };

  // updates the user profile on the middleware database
  updateUserProfile(netReadyUser) {
    return this.http.post(`${this.baseUrl}updateuserprofile`, netReadyUser);
  };
}
