import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginRequest } from '../models/loginRequest';
import { LoginResponse } from '../models/loginResponse';
import { ApiService } from '../services/api.service';
import { UserService } from '../services/user.service';
import { UserOldService } from '../services/user_old.service';

@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
})
export class HomePage implements OnInit{

  fullName = 'LiveLife';
  appId;
  currentUser;
  loginResponse: LoginResponse;
  showLoader = true;
  showError = false;
  errorCode = 'An unknown Error has occured';

  techs = [
    {
      title: 'Angular',
      icon: 'angular',
      description: 'A powerful Javascript framework for building single page apps. Angular is open source, and maintained by Google.',
      color: '#E63135'
    },
    {
      title: 'CSS3',
      icon: 'css3',
      description: 'The latest version of cascading stylesheets - the styling language of the web!',
      color: '#0CA9EA'
    }
  ];
  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private http: ApiService,
    private userOldService: UserOldService,
    private userService: UserService,
  ) {}

  ngOnInit() {
    const appId1 = 'eyJpdiI6IitJT2g2cHluSXM0a0xMc0N3aWFqa0E9PSIsInZhbHVlIjoiS29jNFI5emY4VTNwZ2w4WU5BWUdoQT09IiwibWF';
    const appId2 = 'jIjoiZGIzNmI0YzQwNzFhMjU2YTk0ZjQ2ZDljYjY0OGM3Y2FhMTg5Y2ZmOWJhYzEwY2IzZjM4MWRhYTYxMzQyNTkxOCJ9';
    this.appId = `${appId1}${appId2}`;
    this.getLogin(this.appId);
    // this.route.params.subscribe(params => {
    //   this.appId = params.id;
    //   this.getLogin(this.appId);
    // });
  }

  getLogin(id) {
    const payload = {
      appid: id
    };

    this.http.post('user', payload).subscribe((res: any) => {
        if (res.success) {
          this.currentUser = res.data;
          this.userOldService.setUser(res.data);
          this.userOldService.user.member = res.member;

          // setup login to livelife server
          const loginRequest: LoginRequest = {
            agentCode: this.currentUser.agent_code,
            appId: this.currentUser.app_id
          };

          this.userService.loginUser(loginRequest).subscribe((response: LoginResponse) => {
              this.loginResponse = response;
              // console.log('Login Response:', response);
              // check user status - direct to register page if pending
              // statusid of 3 indicates not netready registered
              if (response.userStatusId === 1)
              {
                this.router.navigate(['userRegister']);
              }

              // set fullName
              this.fullName = `${this.loginResponse.firstName} ${this.loginResponse.lastName}`;
            }, error => {
              console.log('Error connecting to middleware');
          });

        } else if (res.success) {
          //console.log(res.data);
          this.showError = true;
          this.errorCode = res.data;
        }
      }
    );
  }

  openPage(pageName) {
    this.router.navigate([pageName]);
  }

  openCoupons(brand, categoryId) {
    if (brand === 'livelife')
    {
      this.router.navigateByUrl('/coupons/' + brand + '/' + categoryId);
    }
    else
    {
      this.router.navigateByUrl('/coupons/' + brand);
    }
  }

  openFuel() {
    // this.googleAnalyticsService.eventEmitter(
    //   "iFuelU",
    //   "My-Fuel",
    //   "FuelPageClicked",
    //   1
    // );
    this.router.navigate(['fuel']);

    // let fname = this.currentUser.first_name;
    // let lname = this.currentUser.last_name;

    // if (lname === '') {
    //   lname = 'null';
    // }

    // if (fname === 'LIVE_LIFE_PH2' || fname === '') {
    //   fname = 'null';
    // }

    // const baseUrl = 'https://portal.data4me.je/fuel/';
    // const url = `${baseUrl}${this.appId}/${fname}/${lname}`;
    // window.location.href = url;
  }
}
