import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { CouponsPage } from './coupons/coupons.page';
import { HomePage } from './home/home.page';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'rewards/:id',
    component: HomePage
  },
  {
    path: 'rewards',
    component: HomePage
  },
  {
    path: 'coupons/livelife/:categoryId',
    component: CouponsPage
  },
  {
    path: 'ecommerce',
    loadChildren: () => import('./ecommerce/ecommerce.module').then( m => m.EcommercePageModule)
  },
  {
    path: 'userprofile',
    loadChildren: () => import('./userprofile/userprofile.module').then( m => m.UserprofilePageModule)
  },
  {
    path: 'wallet',
    loadChildren: () => import('./wallet/wallet.module').then( m => m.WalletPageModule)
  },
  {
    path: 'coupons',
    loadChildren: () => import('./coupons/coupons.module').then( m => m.CouponsPageModule)
  },
  {
    path: 'fuel',
    loadChildren: () => import('./fuel/fuel.module').then( m => m.FuelPageModule)
  },
  {
    path: 'farmtofork',
    loadChildren: () => import('./farmtofork/farmtofork.module').then( m => m.FarmtoforkPageModule)
  },
  {
    path: 'circulareconomy',
    loadChildren: () => import('./circulareconomy/circulareconomy.module').then( m => m.CirculareconomyPageModule)
  },
  {
    path: 'emergency',
    loadChildren: () => import('./emergency/emergency.module').then( m => m.EmergencyPageModule)
  },
  {
    path: 'user-registration',
    loadChildren: () => import('./user-registration/user-registration.module').then( m => m.UserRegistrationPageModule)
  },
  {
    path: 'shopping-cart',
    loadChildren: () => import('./shopping-cart/shopping-cart.module').then( m => m.ShoppingCartPageModule)
  },
  {
    path: 'messages',
    loadChildren: () => import('./messages/messages.module').then( m => m.MessagesPageModule)
  },
  {
    path: 'wallet-consumer',
    loadChildren: () => import('./wallet/consumer/consumer.module').then( m => m.ConsumerPageModule)
  },
  {
    path: 'wallet-merchant',
    loadChildren: () => import('./wallet/merchant/merchant.module').then( m => m.MerchantPageModule)
  },
  {
    path: 'wallet-payroll',
    loadChildren: () => import('./wallet/payroll/payroll.module').then( m => m.PayrollPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
